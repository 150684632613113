<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 14:00:18
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-24 15:56:05
 * @FilePath: \crm-education\src\views\student\my_notice\index.vue
-->
<template>
  <div class="my-notice">
    <a-card class="cus-card no-border" :bordered="false">
       <a-tabs v-model="activeKey" class="cus-tabs">
        <a-tab-pane key="1">
          <template slot="tab">
            <p class="fs-18">消息通知</p>
          </template>
          <div class="py-5">
            <a-form class="cus-form" layout="inline" :form="form">
              <a-row type="flex" align="middle">
                <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
                  <a-form-item label="通知标题">
                    <a-input v-decorator="['informName']"> </a-input>
                  </a-form-item>
                </a-col>
                <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                  <a-form-item label="通知类型" has-feedback>
                    <a-select class="cus-select" v-decorator="['informType']" placeholder="选择类别">
                      <a-select-option value="">
                        全部通知
                      </a-select-option>
                      <a-select-option value="1">
                        教学通知
                      </a-select-option>
                      <a-select-option value="2">
                        教务通知
                      </a-select-option>
                      <a-select-option value="3">
                        考试通知
                      </a-select-option>
                      <a-select-option value="4">
                        活动通知
                      </a-select-option>
                      <a-select-option value="5">
                        面授通知
                      </a-select-option>
                      <a-select-option value="6">
                        直播通知
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="{ span: 5 }" :xs="{ span: 10 }">
                  <a-form-item label="消息状态" has-feedback>
                    <a-select class="cus-select" v-decorator="['isRead']"  placeholder="消息类别">
                      <a-select-option value="">
                        全部
                      </a-select-option>
                      <a-select-option value="Y">
                        已读
                      </a-select-option>
                      <a-select-option value="N">
                        未读
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col style="margin-left: 30px">
                  <a-form-item>
                    <a-button type="primary" html-type="submit" class="cus-button" @click="onQueryList">
                      查询
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!--<a-list item-layout="horizontal" :data-source="dataList[0]" :pagination="paginationObject">-->
            <!--<div class="list-header d-flex justify-between align-center px-15" slot="header">-->
              <!--<p class="fs-14">通知标题</p>-->
              <!--<p class="fs-14">时间</p>-->
            <!--</div>-->
            <!--<a-list-item slot="renderItem" slot-scope="item">-->
              <!--<div-->
                <!--class="list-item d-flex justify-between align-center px-15 link hover-color-blue"-->
                <!--@click="openModel(item)"-->
              <!--&gt;-->
                <!--<p class="fs-16" :class="{ unread: item.isRead === 'N' }">-->
                  <!--{{ item.informName }}-->
                <!--</p>-->
                <!--<p class="fs-12 color-gray">{{ item.createTime }}</p>-->
              <!--</div>-->
            <!--</a-list-item>-->
          <!--</a-list>-->
          <a-table class="cus-table fs-14" bordered :data-source="dataList[0]" :columns="columns" :pagination="paginationObject" ></a-table>
        </a-tab-pane>
        <!--<a-tab-pane key="2">-->
          <!--<template slot="tab">-->
            <!--<p class="fs-18">我的消息</p>-->
          <!--</template>-->
          <!--<div class="message-box d-flex justify-end align-center mb-10">-->
            <!--<div class="d-flex align-center link" v-if="true">-->
              <!--<span class="out-box cus-icon"></span>-->
              <!--<p class="ml-10 fs-14">已发送</p>-->
            <!--</div>-->
            <!--<div class="d-flex align-center link" v-else>-->
              <!--<span class="in-box cus-icon"></span>-->
              <!--<p class="ml-10 fs-14">收信箱</p>-->
            <!--</div>-->
          <!--</div>-->
          <!--<a-list item-layout="horizontal" :data-source="msgDataList" :pagination="msgPaginationObject">-->
            <!--<div class="list-header d-flex justify-between align-center px-15" slot="header">-->
              <!--<p class="fs-14">内容</p>-->
              <!--<p class="fs-14">时间</p>-->
            <!--</div>-->
            <!--<a-list-item slot="renderItem" slot-scope="item">-->
              <!--<div-->
                <!--class="list-item d-flex justify-between align-center px-15 link hover-color-blue"-->
                <!--@click="setMessageRead(item)"-->
              <!--&gt;-->
                <!--<p class="fs-16" :class="{ unread: item.isRead === 'N' }">-->
                  <!--{{ item.messageName }}-->
                <!--</p>-->
                <!--<p class="fs-12 color-gray">{{ item.createTime }}</p>-->
              <!--</div>-->
            <!--</a-list-item>-->
          <!--</a-list>-->
        <!--</a-tab-pane>-->
      </a-tabs>
<!--      <a-empty>
        <span slot="description"> 该功能正在建设中</span>
      </a-empty>-->
    </a-card>
     <a-modal v-model="readMsg" width="50%">
       <p style="text-align: center" ><b class="fs-20">{{currentMsg.informName || currentMsg.messageName}}</b></p>
       <p style="text-align: center" class="fs-16">{{currentMsg.createTime}}发布</p>
       <template slot="footer">
           <p style="text-align: center"><a-button  type="primary" @click="setNoticeRead(currentMsg)" v-if="currentMsg.isRead=='N'">我已认真完成阅读</a-button></p>
           <p style="text-align: center"><a-button  type="primary" @click="readMsg = !readMsg" v-if="currentMsg.isRead=='Y'">关闭</a-button></p>
       </template>
       <p v-html="currentMsg.informContent || currentMsg.messageContent"></p>
       <br/>
       <div v-if="currentMsg.fileList!=''">
         <p v-for="file in currentMsg.fileList" style="text-align: center">
            <a class="fs-12" :href="file.fileUrl">{{file.fileName}}</a>
            <a class="fs-12" style="margin-left: 10px" target="_blank" :href="previewFile(file.fileUrl)"><a-icon type="file-text"/></a>
         </p>
       </div>
    </a-modal>
  </div>
</template>

<script>
import api from '@/api'
export default {
  name: 'my_notice',
  data() {
    return {
      readMsg: false,
      confirmLoading: false,
      currentMsg: {},
      activeKey: '1',
      form: this.$form.createForm(this, { name: 'my_notice' }),
      dataList: [],
      msgDataList: [],
      noticeParams: {
        informName: '',
        informType: '',
        isRead:'',
      },
      paginationObject: {
        current: 1,
        total: 0,
        pageSize: 10,
        onChange: this.onPageChange
      },
      msgPaginationObject: {
        current: 1,
        total: 0,
        pageSize: 10,
        onChange: this.onMsgPageChange
      },
      columns:[{
        title: '通知标题',
        align: 'left',
        dataIndex: 'informName',
        customRender:(informName, row, index) => {
            const openModel =()=>{
                this.openModel(row);
            }
            return <a onClick={openModel} > {informName} </a>;
        }

      },{
        title: '通知类型',
        align: 'center',
        customRender: (value, row, index) => {
          switch (Number(row.informType)) {
            case 1:
              return "教学通知"
            case 2:
              return "教务通知"
            case 3:
              return "考试通知"
            case 4:
              return "活动通知"
            case 5:
              return "面授通知"
            case 6:
              return "直播通知"
            default:
              return ''
          }
        }
      },{
        title: '接收时间',
        align: 'center',
        dataIndex: 'createTime'
      },{
        title: '状态',
        align: 'center',
        customRender: (value, row, index) => {
          switch (row.isRead) {
              case 'Y':
              return <p style='color:blue' >已读</p>
            case 'N':
              return <p style='color:red' >未读</p>
            default:
              return ''
          }
        }
      },


      ]
    }
  },
  watch: {
    activeKey: {
      handler(nv, ov) {
        if (nv === '1') {
          this.initNotice()
        } else {
          this.initMessage()
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    async init() {
      await this.initNotice()
    },
    onQueryList() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.noticeParams = values
          this.initNotice()
        }
      })
    },
    async initNotice() {
      const params = {
        currentPage: this.paginationObject.current,
        pageSize: this.paginationObject.pageSize,
        informName: this.noticeParams.informName,
        informType: this.noticeParams.informType,
        isRead: this.noticeParams.isRead,
      }
      let res = await api.student.getNotice(params)
      if (res.code === 200) {
        this.dataList = res.data
        this.paginationObject.total = res.pageInfo.totalCount
      }
    },
    async initMessage() {
      const params = {
        currentPage: this.msgPaginationObject.current,
        pageSize: this.msgPaginationObject.pageSize
      }
      let res = await api.student.getMyMessage(params)
      if (res.code === 200) {
        this.msgDataList = res.data
        this.msgPaginationObject.total = res.pageInfo.totalCount
      }
    },
    async setNoticeRead(item) {
      const data = {
        informUserId: item.informUserId
      }

      if (item.isRead === 'N') {
        let res = await api.student.setNoticeRead(data)
        if (res.code === 200) {
          // message.success('已读')
          //this.currentMsg = item
          this.readMsg = !this.readMsg
          this.initNotice()
        }
      } else {
        this.readMsg = !this.readMsg
      }
    },
    async openModel(item){
      this.readMsg = true
      this.currentMsg = item
    },
    async setMessageRead(item) {
      const data = {
        messageUserId: item.messageUserId
      }
      if (item.isRead === 'N') {
        let res = await api.student.setMessageRead(data)
        if (res.code === 200) {
          // message.success('已读')
          this.readMsg = true
          this.currentMsg = item
          this.initNotice()
        }
      } else {
        this.readMsg = true
        this.currentMsg = item
      }
    },
    onPageChange(page, pageSize) {
      this.paginationObject.current = page
      this.initNotice()
    },
    onMsgPageChange(page, pageSize) {
      this.msgPaginationObject.current = page
      this.initMessage()
    },
    previewFile(url){
      return 'http://ow365.cn/?info=3&i=25157&furl='+url;
    },
    async getNoReadCount() {
      const { data = {} } = await api.student.getNoReadCount()
      this.setNoReadMsgCount(data.noReadCount || 0)
    },
  }
}
</script>
<style lang="scss" scoped>
.my-notice {
  ::v-deep .ant-list-header {
    background-color: #eff2fb;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .list-item {
    width: 100%;
    .unread {
      position: relative;
      &::after {
        position: absolute;
        right: -4px;
        top: 0px;
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background-color: #fc3f3f;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
  .message-box {
    .cus-icon {
      display: block;
      width: 18px;
      height: 18px;
      background-size: 14px 14px;
      &.out-box {
        background: url('../../../assets/img/my-notice-outbox.png') no-repeat center center;
      }
      &.in-box {
        background: url('../../../assets/img/my-notice-inbox.png') no-repeat center center;
      }
    }
  }
}
</style>
